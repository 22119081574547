import { Player } from "@lottiefiles/react-lottie-player";
import React, { useEffect, useRef, useState } from "react";

const Footer = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);
  const [lottiePlayer, setLottiePlayer] = useState(null);
  const [currentEarth, setCurrentEarth] = useState(0);

  // 섹션이 화면에 보이는지 감지
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.2 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.disconnect();
      }
    };
  }, []);

  // 애니메이션 플레이어 참조 설정 및 재생
  useEffect(() => {
    if (isVisible && lottiePlayer) {
      lottiePlayer.play();
    }
  }, [isVisible, lottiePlayer]);

  // 지구 아이콘 회전 효과
  useEffect(() => {
    if (!isVisible) return;

    const interval = setInterval(() => {
      setCurrentEarth((prev) => (prev + 1) % 3);
    }, 1000);

    return () => clearInterval(interval);
  }, [isVisible]);

  // 지구 이모지 배열
  const earthEmojis = ["🐱", "😺", "😸"];

  return (
    <div className="bg-white">
      {/* 메인 푸터 */}
      <div ref={sectionRef} className="py-16 md:py-24 bg-sand">
        <div className="container-section text-center">
          <div className="max-w-3xl mx-auto mb-12">
            <div
              className={`relative inline-block transition-all duration-700 ${
                isVisible
                  ? "opacity-100 transform-none"
                  : "opacity-0 transform -translate-y-10"
              }`}
            >
              <h2 className="text-4xl md:text-5xl font-bold mb-2">
                지금이 때입니다.
              </h2>
              <h2 className="text-4xl md:text-5xl font-bold">
                함께 시작하세요
              </h2>
              <div className="absolute -right-8 bottom-0">
                <Player
                  ref={(ref) => setLottiePlayer(ref)}
                  src="/lottie/Get-Ready-Circle.json"
                  style={{ width: "100px", height: "100px" }}
                  keepLastFrame
                />
              </div>
            </div>

            <p
              className={`text-gray-600 mt-10 transition-all duration-700 delay-100 ${
                isVisible
                  ? "opacity-100 transform-none"
                  : "opacity-0 transform -translate-y-10"
              }`}
            >
              당신의 고양이는 최고의 케어를 받을 자격이 있습니다. 그리고 인생은
              너무 짧아서 소중한 반려묘와의 시간을 놓칠 수 없습니다.
              <br />
              <br />
              헬로집사와 함께, 고양이와의 행복한 삶을 시작하세요.
            </p>
          </div>
        </div>
      </div>

      {/* 서브 푸터 */}
      <div className="py-8 bg-white border-t border-gray-200">
        <div className="container-section">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="flex flex-col items-center md:items-start mb-6 md:mb-0">
              <div className="flex items-center mb-4">
                <p className="text-gray-500 mr-2">
                  헬로집사 — 사랑하는 고양이와 함께 만들어짐
                </p>
                <div className="relative w-8 h-8 flex items-center justify-center overflow-hidden">
                  {earthEmojis.map((emoji, index) => (
                    <div
                      key={index}
                      className={`absolute inset-0 flex items-center justify-center transition-opacity duration-500 ${
                        currentEarth === index ? "opacity-100" : "opacity-0"
                      }`}
                    >
                      <span className="text-xl">{emoji}</span>
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex space-x-4">
                <a
                  href="/privacy"
                  className="text-gray-500 hover:text-primary transition-colors"
                >
                  개인정보 정책
                </a>
                <a
                  href="/terms"
                  className="text-gray-500 hover:text-primary transition-colors"
                >
                  이용 약관
                </a>
              </div>
            </div>

            <div className="flex space-x-4">
              <a
                href="https://twitter.com/hellobutler"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-500 hover:text-primary transition-colors"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M22 4s-.7 2.1-2 3.4c1.6 10-9.4 17.3-18 11.6 2.2.1 4.4-.6 6-2C3 15.5.5 9.6 3 5c2.2 2.6 5.6 4.1 9 4-.9-4.2 4-6.6 7-3.8 1.1 0 3-1.2 3-1.2z"></path>
                </svg>
              </a>
              <a
                href="https://linkedin.com/company/hellobutler"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-500 hover:text-primary transition-colors"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path>
                  <rect x="2" y="9" width="4" height="12"></rect>
                  <circle cx="4" cy="4" r="2"></circle>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
