import { Player } from "@lottiefiles/react-lottie-player";
import React, { useState } from "react";

// 아바타 이미지를 사용하기 위한 임시 데이터
const avatars = [
  { id: 1, name: "코코", img: "/images/Julie_28.webp" },
  { id: 2, name: "미야", img: "/images/Augustine_28.webp" },
  { id: 3, name: "나비", img: "/images/Febby_28.webp" },
  { id: 4, name: "루이", img: "/images/Junnie_28.webp" },
  { id: 5, name: "모모", img: "/images/Deercember_28.webp" },
];

const Navbar = () => {
  const [hoveredAvatar, setHoveredAvatar] = useState(null);

  return (
    <nav className="sticky top-0 bg-sand bg-opacity-90 backdrop-blur-sm z-50 border-b border-gray-200">
      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 flex justify-between items-center h-12">
        {/* 로고 */}
        <a href="/" className="flex items-center">
          <img
            src="/images/Ready-Logo_1.svg"
            alt="헬로 버틀러 로고"
            className="h-5 w-auto"
          />
        </a>

        {/* 아바타 섹션 */}
        <div className="flex -space-x-2">
          {avatars.map((avatar) => (
            <div
              key={avatar.id}
              className="relative w-6 h-6 rounded-full overflow-hidden border-2 border-white z-10"
              onMouseEnter={() => setHoveredAvatar(avatar.id)}
              onMouseLeave={() => setHoveredAvatar(null)}
            >
              <img
                src={avatar.img}
                alt={`${avatar.name} 고양이 아바타`}
                className="w-full h-full object-cover"
              />
              {hoveredAvatar === avatar.id && (
                <div className="absolute inset-0">
                  <Player
                    autoplay
                    keepLastFrame
                    src="/lottie/app-explosion.json"
                    style={{ width: "100%", height: "100%" }}
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
