import { Player } from "@lottiefiles/react-lottie-player";
import React, { useEffect, useRef, useState } from "react";

// 기능 데이터
const features = [
  {
    id: 1,
    title: "건강 기록 히스토리",
    description:
      "헬로집사는 고양이의 모든 건강 기록을 연결합니다. 과거의 병원 방문 기록을 확인하거나 앞으로의 예방 접종 일정을 미리 계획할 수 있습니다.",
    image: "/images/Time-Travel.webp",
    animationDelay: 0,
    isFullWidth: true,
  },
  {
    id: 2,
    title: "케어 일정 관리",
    description:
      "그루밍, 목욕, 병원 방문 등 모든 케어 일정을 한눈에 확인하고 간편하게 관리할 수 있습니다.",
    image: "/images/Task-Sidebar.webp",
    squiggle1: "/images/Squiggle_Leave_1.svg",
    squiggle2: "/images/Squiggle_Leave_3.svg",
    animationDelay: 200,
  },
  {
    id: 3,
    title: "활동 추적",
    description:
      "헬로집사는 고양이의 모든 활동을 추적하여 더 이상 건강 상태에 대해 걱정할 필요가 없습니다.",
    image: "/images/Threads.webp",
    squiggle: "/images/Squiggle_Leave_2.svg",
    animationDelay: 400,
    isFullWidth: true,
  },
];

const FeelingReady = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);
  const [lottiePlayer, setLottiePlayer] = useState(null);

  // 섹션이 화면에 보이는지 감지
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.2 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.disconnect();
      }
    };
  }, []);

  // 애니메이션 플레이어 참조 설정
  useEffect(() => {
    if (isVisible && lottiePlayer) {
      lottiePlayer.play();
    }
  }, [isVisible, lottiePlayer]);

  return (
    <div ref={sectionRef} className="py-16 md:py-24 bg-white">
      <div className="container-section">
        <div className="text-center max-w-3xl mx-auto mb-16">
          <div
            className={`relative transition-all duration-700 ${
              isVisible
                ? "opacity-100 transform-none"
                : "opacity-0 transform -translate-y-10"
            }`}
          >
            <h2 className="mb-3">완벽한 고양이 케어로 마음의 평화를</h2>
            <div className="absolute -right-12 top-0">
              <Player
                ref={(ref) => setLottiePlayer(ref)}
                src="/lottie/Every_Circle.json"
                style={{ width: "80px", height: "80px" }}
                keepLastFrame
              />
            </div>
          </div>

          <p
            className={`text-gray-600 mt-6 transition-all duration-700 delay-100 ${
              isVisible
                ? "opacity-100 transform-none"
                : "opacity-0 transform -translate-y-10"
            }`}
          >
            건강 체크나 케어 일정을 잊어버릴 일이 없습니다. 헬로집사는
            고양이의 모든 필요 사항을 체계적으로 관리하여 당신과 고양이가
            건강하고 행복한 삶을 살 수 있도록 도와줍니다.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {features.map((feature) => (
            <div
              key={feature.id}
              className={`bg-sand p-6 rounded-xl shadow-lg transition-all duration-700 transform ${
                isVisible
                  ? "opacity-100 transform-none"
                  : "opacity-0 transform translate-y-10"
              } ${feature.isFullWidth ? "md:col-span-2" : ""}`}
              style={{ transitionDelay: `${feature.animationDelay}ms` }}
            >
              <div className="flex flex-col md:flex-row gap-6">
                <div className="md:w-1/2 flex flex-col">
                  {feature.squiggle1 && (
                    <div className="mb-4">
                      <img src={feature.squiggle1} alt="" className="h-10" />
                    </div>
                  )}

                  {feature.squiggle2 && (
                    <div className="mb-4 text-right">
                      <img
                        src={feature.squiggle2}
                        alt=""
                        className="h-10 ml-auto"
                      />
                    </div>
                  )}

                  {feature.squiggle &&
                    !feature.squiggle1 &&
                    !feature.squiggle2 && (
                      <div className="mb-4">
                        <img src={feature.squiggle} alt="" className="h-10" />
                      </div>
                    )}

                  <div className="mb-6">
                    <h3 className="text-xl font-bold mb-2">{feature.title}</h3>
                    <p className="text-gray-600">{feature.description}</p>
                  </div>
                </div>

                <div
                  className={`${feature.isFullWidth ? "md:w-1/2" : "w-full"}`}
                >
                  <img
                    src={feature.image}
                    alt={feature.title}
                    className="w-full h-auto rounded-lg shadow"
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FeelingReady;
