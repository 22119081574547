import React from "react";
import AllInCalendar from "./components/AllInCalendar";
import FeelingReady from "./components/FeelingReady";
import Footer from "./components/Footer";
import Hero from "./components/Hero";
import MeetingsUpgraded from "./components/MeetingsUpgraded";
import Navbar from "./components/Navbar";
import SuperpoweredMeetings from "./components/SuperpoweredMeetings";

function App() {
  return (
    <div className="App bg-sand">
      <Navbar />
      <Hero />
      <SuperpoweredMeetings />
      <MeetingsUpgraded />
      <FeelingReady />
      <AllInCalendar />
      <Footer />
    </div>
  );
}

export default App;
