import { Player } from "@lottiefiles/react-lottie-player";
import React, { useEffect, useRef, useState } from "react";

// 슬라이더 이미지 데이터
const sliderImages = [
  { id: 1, src: "/images/splash.png", alt: "영양 관리" },
  { id: 2, src: "/images/splash.png", alt: "건강 체크" },
  {
    id: 3,
    src: "/images/image-analysis.png",
    alt: "놀이 시간",
    hasAnimation: true,
  },
  { id: 4, src: "/images/diary.png", alt: "챗봇 케어" },
  { id: 5, src: "/images/settings.png", alt: "설정" },
  { id: 6, src: "/images/image-analysis.png", alt: "사진 분석" },
  { id: 7, src: "/images/diary.png", alt: "일기 쓰기" },
];

const SuperpoweredMeetings = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);
  const [activeSlide, setActiveSlide] = useState(0);

  // 섹션이 화면에 보이는지 감지
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.2 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.disconnect();
      }
    };
  }, []);

  // 슬라이더 자동 회전
  useEffect(() => {
    if (!isVisible) return;

    const interval = setInterval(() => {
      setActiveSlide((prev) => (prev + 1) % sliderImages.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [isVisible]);

  return (
    <div
      ref={sectionRef}
      className={`bg-white py-16 md:py-24 transition-opacity duration-1000 ${
        isVisible ? "opacity-100" : "opacity-0"
      }`}
    >
      <div className="container-section">
        <div className="text-center max-w-3xl mx-auto mb-16">
          <h2 className="mb-6">모든 고양이를 위한 맞춤형 케어 서비스</h2>
          <p className="text-gray-600">
            헬로집사의 고양이 케어 서비스는 즉시 당신의 고양이에게 맞춤형 케어를
            제공하여 몇 초 만에 완벽한 관리 계획을 세울 수 있도록
            설계되었습니다.
          </p>
        </div>

        <div className="overflow-hidden">
          <div className="relative">
            {/* 슬라이더 컨테이너 */}
            <div
              className="flex transition-transform duration-500 ease-in-out"
              style={{
                transform: `translateX(-${
                  activeSlide * (100 / sliderImages.length)
                }%)`,
                width: `${sliderImages.length * 100}%`,
              }}
            >
              {sliderImages.map((image, index) => (
                <div
                  key={image.id}
                  className="relative px-2"
                  style={{ width: `${100 / sliderImages.length}%` }}
                >
                  <div
                    className={`rounded-lg overflow-hidden shadow-lg ${
                      activeSlide === index ? "ring-4 ring-primary" : ""
                    }`}
                  >
                    <img
                      src={image.src}
                      alt={image.alt}
                      className="w-full h-auto"
                    />

                    {image.hasAnimation && (
                      <div className="absolute bottom-6 right-6">
                        <Player
                          autoplay={activeSlide === index}
                          loop
                          src="/lottie/Circle.json"
                          style={{ width: "60px", height: "60px" }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>

            {/* 슬라이더 컨트롤 */}
            <div className="flex justify-center mt-8 space-x-2">
              {sliderImages.map((_, index) => (
                <button
                  key={index}
                  className={`w-3 h-3 rounded-full transition-all ${
                    activeSlide === index
                      ? "bg-primary w-8"
                      : "bg-gray-300 hover:bg-gray-400"
                  }`}
                  onClick={() => setActiveSlide(index)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuperpoweredMeetings;
