import { Player } from "@lottiefiles/react-lottie-player";
import React, { useEffect, useRef, useState } from "react";

// 업그레이드 데이터
const upgrades = [
  {
    id: 1,
    title: "맞춤형 식단 계획",
    description:
      "식단 템플릿을 사용하면 클릭 한 번으로 고양이에게 완벽한 식단을 제공할 수 있습니다. 자동 알림 기능으로 규칙적인 식사 시간을 유지할 수 있습니다.",
    image: "/images/Templates.webp",
    squiggle: "/images/Squiggle_Upgrades_1.svg",
    animationDelay: 0,
  },
  {
    id: 2,
    title: "건강 모니터링",
    description:
      "다양한 건강 체크 도구를 통해 몇 번의 클릭만으로 고양이의 건강 상태를 모니터링하고 기록할 수 있습니다.",
    image: "/images/Powerful-Tools.webp",
    squiggle: "/images/Squiggle_Upgrades_3.svg",
    animationDelay: 200,
  },
  {
    id: 3,
    title: "스마트 케어 알림",
    description:
      "그루밍, 목욕, 병원 방문 등의 케어 일정을 설정하고 알림을 받으세요. 헬로집사는 자동으로 이를 정리하여 당신과 고양이를 위해 준비합니다.",
    image: "/images/Tasks.webp",
    squiggle: "/images/Squiggle_Upgrades_2.svg",
    animationDelay: 400,
  },
];

const MeetingsUpgraded = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);
  const [lottiePlayer, setLottiePlayer] = useState(null);

  // 섹션이 화면에 보이는지 감지
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.2 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.disconnect();
      }
    };
  }, []);

  // 애니메이션 플레이어 참조 설정
  useEffect(() => {
    if (isVisible && lottiePlayer) {
      lottiePlayer.play();
    }
  }, [isVisible, lottiePlayer]);

  return (
    <div ref={sectionRef} className="py-16 md:py-24 bg-sand">
      <div className="container-section">
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-16">
          <div
            className={`max-w-2xl mb-8 md:mb-0 transition-all duration-700 ${
              isVisible
                ? "opacity-100 transform-none"
                : "opacity-0 transform -translate-x-10"
            }`}
          >
            <div className="relative">
              <h2 className="mb-3">고양이 케어의 업그레이드</h2>
              <div className="absolute -bottom-4 left-0 w-full">
                <Player
                  ref={(ref) => setLottiePlayer(ref)}
                  src="/lottie/Underline.json"
                  style={{ width: "100%", height: "40px" }}
                  keepLastFrame
                />
              </div>
            </div>
            <p className="text-gray-600 mt-6 max-w-xl">
              식단 계획, 건강 기록, 케어 알림을 통해 쉽게 고양이의 일상을 관리할
              수 있습니다.
            </p>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {upgrades.map((upgrade) => (
            <div
              key={upgrade.id}
              className={`bg-white p-6 rounded-xl shadow-lg transition-all duration-700 transform ${
                isVisible
                  ? "opacity-100 transform-none"
                  : "opacity-0 transform translate-y-10"
              }`}
              style={{ transitionDelay: `${upgrade.animationDelay}ms` }}
            >
              <div className="mb-4">
                <img src={upgrade.squiggle} alt="" className="h-10" />
              </div>

              <div className="mb-6">
                <h3 className="text-xl font-bold mb-2">{upgrade.title}</h3>
                <p className="text-gray-600">{upgrade.description}</p>
              </div>

              <div className="mt-auto">
                <img
                  src={upgrade.image}
                  alt={upgrade.title}
                  className="w-full h-auto rounded-lg shadow"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MeetingsUpgraded;
