import { Player } from "@lottiefiles/react-lottie-player";
import React, { useEffect, useRef, useState } from "react";

// 기능 태그 데이터
const features = [
  { id: 1, text: "모바일 앱", delay: 0 },
  { id: 2, text: "간편한 사용법", delay: 100 },
  { id: 3, text: "다크 & 라이트 모드", delay: 200 },
  { id: 4, text: "AI 분석", delay: 300 },
  { id: 5, text: "푸시 알림", delay: 400 },
];

const AllInCalendar = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);
  const [lottiePlayer1, setLottiePlayer1] = useState(null);
  const [lottiePlayer2, setLottiePlayer2] = useState(null);
  const [lottiePlayerApp, setLottiePlayerApp] = useState(null);

  // 섹션이 화면에 보이는지 감지
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.2 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.disconnect();
      }
    };
  }, []);

  // 애니메이션 플레이어 참조 설정 및 재생
  useEffect(() => {
    if (isVisible) {
      if (lottiePlayer1) lottiePlayer1.play();
      if (lottiePlayer2) lottiePlayer2.play();

      // 앱 아이콘 애니메이션은 약간 지연 후 재생
      const timer = setTimeout(() => {
        if (lottiePlayerApp) lottiePlayerApp.play();
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [isVisible, lottiePlayer1, lottiePlayer2, lottiePlayerApp]);

  return (
    <div
      ref={sectionRef}
      className="py-16 md:py-24 bg-sand relative overflow-hidden"
    >
      <div className="container-section">
        <div className="text-center max-w-3xl mx-auto mb-16">
          <div
            className={`relative inline-block transition-all duration-700 ${
              isVisible
                ? "opacity-100 transform-none"
                : "opacity-0 transform -translate-y-10"
            }`}
          >
            <h2 className="mb-3">
              사용하기 쉬운
              <br />앱 하나로 모든 케어가
            </h2>
            <div className="absolute right-0 top-0">
              <Player
                ref={(ref) => setLottiePlayer1(ref)}
                src="/lottie/Splash_1.json"
                style={{ width: "60px", height: "60px" }}
                keepLastFrame
              />
            </div>
            <div className="absolute left-0 bottom-0">
              <Player
                ref={(ref) => setLottiePlayer2(ref)}
                src="/lottie/Splash.json"
                style={{ width: "80px", height: "80px" }}
                keepLastFrame
              />
            </div>
          </div>

          <p
            className={`text-gray-600 mt-6 transition-all duration-700 delay-100 ${
              isVisible
                ? "opacity-100 transform-none"
                : "opacity-0 transform -translate-y-10"
            }`}
          >
            당신의 고양이가 사랑할 직관적이고 포괄적인 케어 솔루션을 한 앱에
            모두 담았습니다.
          </p>

          <div
            className={`flex flex-wrap justify-center gap-3 mt-8 transition-all duration-700 delay-200 ${
              isVisible
                ? "opacity-100 transform-none"
                : "opacity-0 transform -translate-y-10"
            }`}
          >
            {features.map((feature) => (
              <div
                key={feature.id}
                className={`bg-white py-2 px-4 rounded-full shadow-md transition-all duration-500 transform ${
                  isVisible
                    ? "opacity-100 transform-none"
                    : "opacity-0 transform -translate-y-4"
                }`}
                style={{ transitionDelay: `${feature.delay + 300}ms` }}
              >
                <span className="text-sm font-medium">{feature.text}</span>
              </div>
            ))}
          </div>
        </div>

        <div
          className={`relative mt-20 transition-all duration-1000 ${
            isVisible
              ? "opacity-100 transform-none"
              : "opacity-0 transform scale-95"
          }`}
        >
          <img
            src="/images/Calendar_Footer.webp"
            alt="고양이 케어 앱 대시보드"
            className="w-full rounded-lg shadow-2xl"
          />

          <div
            className={`absolute bottom-5 left-1/2 transform -translate-x-1/2 transition-all duration-700 delay-500 ${
              isVisible
                ? "opacity-100 transform-none"
                : "opacity-0 transform -translate-y-10"
            }`}
          >
            <div className="relative">
              <img
                src="/images/App-Icon.webp"
                alt="앱 아이콘"
                className="w-16 h-16 md:w-20 md:h-20 rounded-xl shadow-lg"
              />
              <div className="absolute inset-0">
                <Player
                  ref={(ref) => setLottiePlayerApp(ref)}
                  src="/lottie/app-explosion.json"
                  style={{ width: "100%", height: "100%" }}
                  speed={0.7}
                  keepLastFrame
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllInCalendar;
