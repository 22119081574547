import { Player } from "@lottiefiles/react-lottie-player";
import React, { useEffect, useRef, useState } from "react";

const cursorTexts = ["코코", "고양이", "털관리", "사료", "건강"];

const Hero = () => {
  const [currentCursorText, setCurrentCursorText] = useState(0);
  const [showCursor, setShowCursor] = useState(false);
  const [displayText, setDisplayText] = useState("");
  const [isTyping, setIsTyping] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);
  const [animatedElements, setAnimatedElements] = useState({
    subtext: false,
    title1: false,
    title2: false,
    description: false,
    calendar: false,
    video: false,
  });
  const videoRef = useRef(null);

  // 타이핑 효과를 위한 커서 텍스트 변경
  useEffect(() => {
    const cursorTimer = setTimeout(() => {
      setShowCursor(true);
    }, 1500);

    return () => clearTimeout(cursorTimer);
  }, []);

  // 타이핑 효과 애니메이션
  useEffect(() => {
    if (!showCursor) return;

    let timeout;
    const currentWord = cursorTexts[currentCursorText];

    if (isTyping) {
      if (displayText.length < currentWord.length) {
        // 타이핑 중
        timeout = setTimeout(() => {
          setDisplayText(currentWord.slice(0, displayText.length + 1));
        }, 150);
      } else {
        // 타이핑 완료, 잠시 대기
        timeout = setTimeout(() => {
          setIsTyping(false);
          setIsDeleting(true);
        }, 1500);
      }
    } else if (isDeleting) {
      if (displayText.length > 0) {
        // 삭제 중
        timeout = setTimeout(() => {
          setDisplayText(displayText.slice(0, -1));
        }, 100);
      } else {
        // 삭제 완료, 다음 단어로
        setIsDeleting(false);
        setIsTyping(true);
        setCurrentCursorText((prev) => (prev + 1) % cursorTexts.length);
      }
    }

    return () => clearTimeout(timeout);
  }, [showCursor, displayText, isTyping, isDeleting, currentCursorText]);

  // 애니메이션 효과
  useEffect(() => {
    // 순차적으로 요소들을 나타내기
    const animations = {
      subtext: setTimeout(
        () => setAnimatedElements((prev) => ({ ...prev, subtext: true })),
        300
      ),
      title1: setTimeout(
        () => setAnimatedElements((prev) => ({ ...prev, title1: true })),
        600
      ),
      title2: setTimeout(
        () => setAnimatedElements((prev) => ({ ...prev, title2: true })),
        900
      ),
      description: setTimeout(
        () => setAnimatedElements((prev) => ({ ...prev, description: true })),
        1200
      ),
      calendar: setTimeout(
        () => setAnimatedElements((prev) => ({ ...prev, calendar: true })),
        1500
      ),
      video: setTimeout(
        () => setAnimatedElements((prev) => ({ ...prev, video: true })),
        1800
      ),
    };

    // 비디오 재생 타이머
    const videoTimer = setTimeout(() => {
      if (videoRef.current) {
        videoRef.current.play();
      }
    }, 3000);

    return () => {
      Object.values(animations).forEach((timer) => clearTimeout(timer));
      clearTimeout(videoTimer);
    };
  }, []);

  return (
    <div className="container-section pt-4 pb-12 md:pt-6 md:pb-20">
      <div className="flex flex-col lg:flex-row items-center justify-center gap-8">
        <div className="w-full lg:w-1/2 space-y-6 text-center lg:text-left">
          <div
            className={`transition-opacity duration-500 ${
              animatedElements.subtext ? "opacity-100" : "opacity-0"
            }`}
          >
            <p className="text-gray-500 font-medium">
              당신의 고양이를 위한 케어
            </p>
          </div>

          <div className="space-y-2">
            <h1
              className={`transition-all duration-500 ${
                animatedElements.title1
                  ? "opacity-100 transform-none"
                  : "opacity-0 transform scale-90"
              }`}
            >
              고양이 케어
            </h1>

            <div className="flex items-center justify-center lg:justify-start">
              <h1
                className={`transition-all duration-500 mr-2 ${
                  animatedElements.title2
                    ? "opacity-100 transform-none"
                    : "opacity-0 transform scale-90"
                }`}
              >
                관리해야 할
              </h1>

              {showCursor && (
                <div className="inline-flex items-center h-16 md:h-20 overflow-visible">
                  <div className="flex items-center">
                    <span className="font-bold text-5xl md:text-6xl leading-none">
                      {displayText}
                    </span>
                    <div className="w-[3px] h-12 md:h-14 bg-primary animate-cursor-blink ml-1 self-center"></div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <p
            className={`text-gray-700 mx-auto lg:mx-0 max-w-lg transition-opacity duration-500 ${
              animatedElements.description ? "opacity-100" : "opacity-0"
            }`}
          >
            헬로집사는 당신의 고양이를 위한 종합 케어 서비스로, 건강, 영양,
            놀이, 미용 등을 체계적으로 관리하여 고양이가 건강하고 행복한 삶을 살
            수 있도록 도와줍니다.
          </p>
        </div>

        <div className="w-full lg:w-1/2 relative">
          <img
            src="/images/Calendar_Hero.png"
            alt="고양이 케어 앱 메인 화면"
            className={`w-full rounded-lg shadow-xl mb-6 transition-all duration-1000 ${
              animatedElements.calendar
                ? "opacity-100 transform-none"
                : "opacity-0 transform translate-y-8 scale-98"
            }`}
          />

          <div
            className={`relative rounded-xl overflow-hidden aspect-video shadow-2xl transition-all duration-700 ${
              animatedElements.video
                ? "opacity-100 transform-none"
                : "opacity-0 transform scale-90"
            }`}
          >
            <video
              ref={videoRef}
              className="w-full h-full object-cover"
              playsInline
              muted
              loop
              poster="/images/Poster.png"
            >
              <source src="/videos/hero-video.mp4" type="video/mp4" />
            </video>

            {/* 스퀴글 애니메이션들 */}
            <div className="absolute top-0 left-0">
              <Player
                autoplay
                keepLastFrame
                src="/lottie/Squiggle-top-left.json"
                style={{ width: "120px", height: "120px" }}
              />
            </div>
            <div className="absolute bottom-0 left-0">
              <Player
                autoplay
                keepLastFrame
                src="/lottie/Squiggle-bottom-left.json"
                style={{ width: "120px", height: "120px" }}
              />
            </div>
            <div className="absolute bottom-0 right-0">
              <Player
                autoplay
                keepLastFrame
                src="/lottie/Squiggle-bottom-right.json"
                style={{ width: "120px", height: "120px" }}
              />
            </div>
            <div className="absolute top-0 right-0">
              <Player
                autoplay
                keepLastFrame
                src="/lottie/Squiggle-top-right.json"
                style={{ width: "120px", height: "120px" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
